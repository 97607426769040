import { Resource } from '../i18n';

export const it: Resource = {
  alert: {
    WAREHOUSESKU_NOT_AVAILABLE: 'Ops, il prodotto non è disponibile, rimuovilo per proseguire',
  },
  cart: {
    summary: {
      totalDiscount: 'Stai risparmiando',
    },
  },
  checkout: {
    edit: 'Modifica',
  },
  contest: {
    registration: {
      cta: {
        toCollection: 'Scopri la collezione',
      },
      customerTitle: 'ciao {{name}} {{surname}}',
      guestTitle: 'Sei già registrato?',
      login: 'Login',
      messages: {
        alredyRegistered: "L'iscrizione al contest risulta essere già effettuata",
        checkEmailNotification:
          'Controlla nella tua casella di posta, ti abbiamo inviato una email di conferma iscrizione.',
        emailNotification: 'Riceverai a breve una email di conferma.',
        registrationSuccess: 'Grazie per esserti iscritto!',
      },
    },
    regulation: 'Leggi il regolamento',
    subscribe: 'iscriviti',
    subscribeLogin: 'iscriviti/login',
  },
  footer: {
    giftCard: {
      title: 'Gift card Equestro',
    },
  },
  form: {
    contestForm: {
      adult: {
        adult: 'Maggiorenne',
        minor: 'Minorenne',
      },
      disclaimer: {
        label: 'Carica la liberatoria',
      },
      disclaimerText:
        "Scarica qui la liberatoria ed effettua l'upload del documento compilato e firmato dal genitore o tutore legale.",
      file: {
        label: 'Carica il video (formato mp4, peso massimo 20MB)',
      },
      instagram: {
        label: 'Instagram Nickname',
        placeholder: '@profiloInstagram',
      },
      photo: {
        label: 'Carica le foto (formato jpeg; peso max 1mb)',
        placeholder: {
          liveIt: 'LIVE IT (fuori dalla scuderia: cinema, scuola, lavoro, aperitivo ecc.)',
          rideIt: 'RIDE IT (con il tuo cavallo in scuderia, gara o allenamento)',
        },
      },
      regulation: 'Dichiaro di aver letto ed accettato il regolamento del contest',
      tiktok: {
        label: 'Tiktok Nickname',
        placeholder: '@profiloTikTok',
      },
    },
    customerForm: {
      children: {
        buttons: {
          add: {
            label: 'Aggiungi un cavallo',
          },
          remove: {
            label: 'Rimuovi',
          },
        },
        name: {
          label: 'Nome cavallo',
        },
      },
    },
    newsletterForm: {
      topics: {
        horse: 'Cavallo',
        man: 'Uomo',
        woman: 'Donna',
        young: 'Young rider',
      },
    },
    personalDataForm: {
      phoneNumbers: { label: 'Telefono' },
    },
  },
  header: {
    sendTo: 'Spedire a {{country}} | {{locale}}',
  },
  i18nTest: 'Questo è un test da store-equestro',
  misc: {
    newsletterSubscription: 'Iscriviti e ricevi 10% di sconto',
    storeName: 'Equestro',
  },
  product: {
    colorsCount_one: '{{count}} colore',
    colorsCount_other: '{{count}} colori',
  },
  welcome: 'Benvenuto su store-equestro!',
};
