import './Icon.scss';

import { b2x } from '@b2x/react/src';

export const iconsNames = [
  'bell',
  'bullet',
  'clubhouse',
  'tik-tok',
  'visible',
  'hidden',
  'call-center',
  'carbon-finish',
  'cart',
  'coupon',
  'credit-card',
  'breathable',
  'breathable2',
  'delete',
  'delivery',
  'email',
  'facebook',
  'filter',
  'flag',
  'hight-resistence',
  'hypoallergenic',
  'hypoallergenic2',
  'info',
  'instagram',
  'insulating',
  'large-arrow-left',
  'large-arrow-right',
  'logout',
  'menu',
  'minus',
  'natural-deodorize',
  'plus',
  'privacy',
  'profile',
  'profile-full',
  'quick-dry',
  'quote-dx',
  'quote-sx',
  'search',
  'sharing-facebook',
  'sharing-messanger',
  'sharing-twitter',
  'sharing-whatapp',
  'sharing',
  'shock-absorbing',
  'size',
  'small-arrow-down',
  'small-arrow-left',
  'small-arrow-right',
  'small-arrow-up',
  'soft',
  'trash',
  'traslate',
  'waterprof',
  'windprof',
  'wishlist-full',
  'wishlist',
] as const;
export type IconName = (typeof iconsNames)[number];

export type IconSize = 12 | 14 | 16 | 20 | 25 | 30 | 35 | 48 | 72;

export interface IconProps extends b2x.IconProps<IconName, IconSize> {}

export const Icon = (props: IconProps) => <b2x.Icon {...props} />;

export const isIconName = (name?: string): name is IconName => b2x.isIconName(iconsNames, name);
